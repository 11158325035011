.footer-div {
  display: grid;
  grid-template-rows: auto;
  color: white;
  font-family: var(--semi-embellished-font);
  align-self: end;
  justify-self: right;

  z-index: 2;
}

.footer-div>* {
  grid-row: 1 / span 1;
  font-size: smaller;
}

.footer-div a,
.footer-div p {
  align-self: center;
  transition: 0.4s;
  margin: 1px;
  padding: 5px;
}

.footer-div a:active {
  background: hsla(var(--whites-extract-color), 1);
  outline: none !important;
  border: none;
}

.footer-div a:hover {
  background: hsla(var(--whites-extract-color), 1);
  color: hsla(var(--blacks-extract-color), 1);
  outline: none !important;
  border: none;
}

.footer-div a:focus {
  background: hsla(var(--whites-extract-color), 1);
  outline: none !important;
}

.by {
  display: grid;
  margin: auto auto auto 50px;
}