.classname_toogle_visibility_button>a,
.edit_button>a {
    display: none;
}

.classname_toogle_visibility_link>button {
    display: none;
}

.edit_button {
    grid-row: 2 / span 2;
    grid-column: 5 / span 3;
    z-index: 1;
}

#editarfundo_edit_button {
    grid-row: 4 / span 1;
    grid-column: 4 / span 1;
    align-self: center;
}

#event_input_edit_button {
    grid-row: 2 / span 2;
    grid-column: 4 / span 2;
    text-align: right;
    align-self: end;
}

#details_input_edit_button {
    grid-row: 4 / span 1;
    grid-column: 4 / span 2;
    /* text-align: right; */
    align-self: baseline;
}

.edit_button>.button-panel-on-hover {
    background-color: rgba(0, 0, 0, 0.5);
    color: hsla(var(--featured-extract-color), 1);
    width: inherit;
    outline-style: dashed;
    outline-width: thin;
}

.edit_button>.button-panel-on-hover:active,
.edit_button>.button-panel-on-hover:hover {
    background-color: hsla(var(--featured-extract-color), 1);
    color: white;
    text-decoration-line: underline;
    width: inherit;
}

.classname_toogle_visibility_link.x-button {
    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
}

.classname_toogle_visibility_link {
    width: 24px;
    height: 24px;

    grid-row: 2 / span 1;
    grid-column: 7 / span 1;

    z-index: 1;
}

.modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.95);
}

.modal>form {
    margin: 20vh auto;
}

.modal>.form-div2 {
    margin: 12vh auto;
    grid-template-rows: auto auto auto;
}

#contacts-div>div>div.togglevisibility-div>div.togglevisibility-child>div.modal>form,
#form-div-editform,
#form-div-registerform {
    margin: 12vh auto;
}

#contacts-div>div>div.togglevisibility-div>div.togglevisibility-child>div.modal_close {
    top: 13vh;
}

#horario-description>div.togglevisibility-div>div.togglevisibility-child>div.modal_close {
    top: 20vh;
}

.modal_close {
    position: fixed;
    top: 21vh;
    right: 26vw;
    z-index: 2;
    background-color: transparent;
}

.modal_close button {
    color: hsla(var(--featured-extract-color), 1);
}

.modal_close button:hover {
    background-color: hsla(var(--featured-extract-color), 1);
    color: white;
}

#clientlist-table1>tbody>tr>td.clientlist-column-21>div>div.togglevisibility-child>div.modal_close,
.mainpage-div>.togglevisibility-div>.togglevisibility-child>.modal_close,
.card-div>.togglevisibility-div>.togglevisibility-child>.card-span~.modal_close {
    display: none;
}

.classname_toogle_visibility_button {
    grid-row: 4 / span 1;
    grid-column: 5 / span 1;

    display: grid;
    grid-template-rows: repeat(auto-fill, 52px);
}

#portfolio-panel-on-hover {
    grid-row: 6 / span 1;
}

#horarios-panel-on-hover {
    grid-row: 7 / span 1;
}

#contactos-panel-on-hover {
    grid-row: 8 / span 1;
}

#clientlist-panel-on-hover {
    grid-row: 9 / span 1;
}

div#photo_details_add_button {
    grid-row: 4 / span 1;
}

.togglevisibility-div .classname_toogle_visibility_button>.togglevisibility-child {
    grid-row: 3 / span 3;
    grid-column: 3 / span 2;
}

.togglevisibility-div .classname_toogle_visibility_link>.togglevisibility-child {
    grid-row: 3 / span 3;
    grid-column: 3 / span 2;
}

.div-panel-on-hover {
    border: none;
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
}

.button-panel-on-hover,
.button-panel-on-hover:active {
    background-color: hsl(0deg 0% 100% / 75%);
    color: black;

    width: 155px;
    height: fit-content;
    text-align: right;
    z-index: 1;
}

.button-panel-on-hover:hover+div,
.button-panel-on-hover:active+div {
    background-color: white;
}

.togglevisibility-div {

    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    display: grid;

    grid-template-rows: minmax(0px, 5px) minmax(0px, 24px) minmax(0px, 24px) auto minmax(0px, 24px) minmax(0px, 24px) minmax(0px, 5px);
    grid-template-columns: minmax(0px, 5px) minmax(0px, 24px) minmax(0px, 24px) auto minmax(0px, max-content) minmax(0px, 24px) minmax(0px, 24px) minmax(0px, 5px);

    justify-self: right;

    width: 100%;
    height: 100%;
}

.togglevisibility-div a.icons-link {
    width: 24px;
    height: 24px;

    grid-row: 2 / span 1;
    grid-column: 2 / span 1;
}

.togglevisibility-child {

    grid-row: 4 / span 1;
    grid-column: 3 / span 2;

    background-color: rgb(44 46 45 / 80%);
    color: white;
    width: inherit;
    margin: auto auto auto auto;
    z-index: 2;
}

button {
    display: inline-block;

    padding: 1rem 2rem;
    margin: 0;
    border: none;

    color: hsla(var(--my-dark-gray-extract-color), 1);
    text-decoration: none;
    font-family: var(--simple-info-font);
    font-size: 1rem;
    text-align: center;

    background-color: transparent;

    cursor: pointer;
    transition: background 250ms ease-in-out,
        transform 150ms ease;

    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover {
    background: hsla(var(--my-light-gray-extract-color), 1);
}

button:active {
    transform: scale(0.99);
}