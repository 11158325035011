h1,
h2,
h3,
h4,
h5 {
    font-family: var(--simple-info-font);
    line-height: 1.0;
    font-weight: 400;

    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
p,
span {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

}

.invisible_element {
    display: none;
}

a:link,
a:visited,
a:hover,
a:active {
    text-decoration: none;
    color: inherit;
}

.after-nav-bar {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
}

.after-nav-bar>div {
    grid-row: 1 /span 1;
    grid-column: 1 /span 1;
}

.mainpage-div {
    display: grid;
    grid-template-rows: auto auto auto auto auto auto auto;
    grid-template-columns: auto;
}

.clientlist-div,
.portfolio-div {
    max-width: 79vw;
    max-height: 79vh;
}

/* .modal {
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: black;
    background-color: hsl(0deg 0% 0% / 90%);
}

.modal > form {
    margin: 25vh auto;
    padding: 20px;
    width: 80%;
} */

.page_signin_div {
    display: grid;
}

.center_button {
    grid-row: 1 / span 1;
    grid-column: 1 / span 1;
    height: fit-content;
    align-self: end;

    justify-self: center;
    margin-bottom: 50px;
    background-color: var(--warning-or-caution-color);
    color: hsla(var(--blacks-extract-color), 1);
}

.fullscreen-text-description {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.fullscreen-div {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
}

.fullscreen-img {
    width: 100%;
    height: 300vh;
    object-fit: cover;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.overlay2 {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    background-color: hsla(var(--blacks-extract-color), 0.6);
    color: hsla(var(--whites-extract-color), 1);
    font-family: var(--simple-info-font);
    z-index: 2;
}

.overlay2 div {
    margin: 20px;
    margin-top: 50px;
    margin-bottom: 200px;
}

.ident {
    margin-left: 20px;
}

.accountmanager-notloggedin-div,
.accountmanager-loggedin-div {
    color: hsla(var(--whites-extract-color), 1);
    font-family: var(--simple-info-font);
    position: absolute;
    z-index: 3;
    top: 0;
    right: 35px;
    padding: 10px;
    margin: 5px;
}

.accountmanager-loggedin-div {
    background-color: var(--sucess-or-loggedin-color);
}

.accountmanager-notloggedin-div {
    background-color: var(--error-or-notloggedin-color);
}

.accountmanager-notloggedin-div a:hover,
.accountmanager-loggedin-div a:hover {
    text-decoration: underline;
}

.delete_button {
    border: none;
    text-decoration: none;
    color: hsla(var(--error-or-notloggedin-extract-color), 1);
    cursor: pointer;
    text-align: center;
    -moz-appearance: none;
    font-family: var(--simple-info-font);
    padding: 10px;

    position: fixed;
    z-index: 1;
    left: 25%;
    bottom: 0;
    overflow: auto;
    background-color: darkred;
}

.nav-search-div {
    width: -webkit-fill-available;
    margin: 20px 15px;
    grid-column: 1 / span 1;
    grid-row: 2 / span 1;
}

:focus-visible {
    outline: auto 1px;
}

@media only screen and (min-width: 670px) and (-webkit-min-device-pixel-ratio: 2){
    div > .presentation-description {
        grid-template-columns: 40px auto auto;
    }
    div > .presentation-logo-div {
        margin: 40px auto;
    }
    #root > div > div > div.mainpage-div > div > div > #portfolio-panel-on-hover{
        grid-row: 2 / span 1;
    }
    #root > div > div > div.mainpage-div > div > div > #horarios-panel-on-hover {
        grid-row: 3 / span 1;
    }
    #root > div > div > div.mainpage-div > div > div > #contactos-panel-on-hover {
        grid-row: 4 / span 1;
    }
    #root > div > div > div.mainpage-div > div > div > #clientlist-panel-on-hover {
        grid-row: 5 / span 1;
    }
    div > .togglevisibility-child {
        grid-row: 2 / span 4;
        grid-column: 2 / span 3;
        align-self: center;
    }
    div > .contacts-div h3{
        font-size: larger;
    }
    div > .togglevisibility-child,
    div > .portfolio-description,
    div > .nav-search-div{
        margin: 0;
    }
    .mainpage-div {
        height: 100vh;
    }
    div > #vcardgallery-div {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 200px));
        grid-gap: 0px;
        justify-items: center;
    }
    div > #vcardgallery-div>div {
        width: 200px;
        height: 200px;
    }
    .map{
        display:none;
    }
    div > .detalhes{
        font-size: small;
    } 
}

@media only screen and (max-width: 670px){

    .after-nav-bar {
        grid-template-rows: auto auto;
    }

    .after-nav-bar>div {
        grid-row: unset;
    }

    .footer-div {
        grid-template-columns: auto auto auto;
        grid-template-rows: auto auto;
    }

    .footer-div>.by {
        grid-column: 1 / span 3;
        grid-row: 2 / span 1;
    }

    .mainpage-div {
        grid-template-rows: auto auto auto auto auto;
    }

    div#presentation-div,
    #root>div>div>div.mainpage-div>div:nth-child(2) {
        margin-top: 20vh;
        grid-row: 2 / span 1;
    }

    #intro-div {
        grid-row: 2/span 1;
    }

    #root>div>div>div.mainpage-div>div {
        grid-column: unset;
        grid-row: unset;
        display: unset;
        grid-template-rows: none;
        grid-template-columns: none;
    }

    #contactos-panel-on-hover,
    #horarios-panel-on-hover,
    #portfolio-panel-on-hover,
    #clientlist-panel-on-hover {
        width: auto;
    }

    .portfolio-div {
        margin: 20px auto;
    }

    #root>div>div>div.mainpage-div>.togglevisibility-div {
        grid-template-rows: unset;
        display: grid;
        grid-template-rows: auto auto;
    }

    div.togglevisibility-div {
        height: unset;
    }

    #root>div>div>div.mainpage-div>div>div.togglevisibility-child {
        grid-row: 2 / span 1;
        grid-column: 1 / span 1;
    }

    #root>div>div>div.mainpage-div>div>div.classname_toogle_visibility_button {
        grid-row: 1 / span 1;
        grid-column: 1 / span 1;
    }

    #portfolio-panel-on-hover,
    #horarios-panel-on-hover,
    #contactos-panel-on-hover,
    #clientlist-panel-on-hover {
        grid-row: unset;
        grid-column: unset;
    }

    #root>div>div>div.mainpage-div>div>div {
        grid-template-rows: unset;
    }

    #root>div>div>div.mainpage-div>div:nth-child(3) {
        margin-top: 20px;
    }

    .togglevisibility-div>#editarfundo_edit_button {
        grid-row: 2 / span 1;
        grid-column: 2 / span 2;
    }

    .togglevisibility-div>#editarlogo_edit_button {
        grid-row: 4 / span 1;
        grid-column: 4 / span 1;
        z-index: 1;
    }

    .togglevisibility-child>div>div>div>div>button {
        padding: 0px;
    }

    .contacts-description>.map {
        grid-column: 1 / span 1;
        grid-row: 2 / span 1;
    }

    #contacts-div>div>div.contact-text-div>div>div.logo-div {
        grid-template-columns: unset;
    }

    div#details_input_edit_button {
        text-align-last: center;
        margin: 85px auto;
    }

    .modal>.form-div,
    .modal>.file-upload-div,
    .form-div3,
    .modal>.form-div3 {
        margin: 100px auto;
        width: auto;
    }

    .form-div,
    .form-div3 {
        width: unset;
    }

    .togglevisibility-child>.modal_close {
        top: 100px;
        right: 0vw;
    }

    .togglevisibility-child>.modal_close>button {
        padding: 10px;
    }

    .field>.divideinsix,
    .field>.divideintwo {
        display: grid;
        grid-auto-flow: unset;
        grid-template-rows: auto;
    }

    .divideintwo>.addeventstartdate,
    .divideintwo>.addeventstarthour,
    .divideintwo>.addeventenddate,
    .divideintwo>.addeventendhour {
        justify-self: unset;
    }

    input[type=date],
    input[type=time],
    input[type=checkbox] {
        width: auto;
    }

    .page_signin_div>.presentation-div {
        margin-bottom: 40vh;
    }

    .input-div-1>.morada_in {
        display: unset;
    }

    .morada_in>.codigo_postal_in {
        display: unset;
    }

    #input-div-2-editform>div:nth-child(1) {
        align-self: end;
    }

    .carddetails-div>.image-gallery {
        display: unset;
    }

    .carddetailsposts-div-vt>.post-image-vt {
        width: 100%;
    }

    .carddetailsposts-div-hz>.post-image-hz {
        width: 100%;
    }

    .carddetailsposts-div-sq>.post-image-sq {
        width: 100%;
    }

    #carddetails-div>div.togglevisibility-div>div {
        grid-row: 2 / span 1;
        grid-column: 6 / span 1;
    }

    .sucess-message-true,
    .warning-message-true,
    .error-message-true {
        margin: 0;
    }
}